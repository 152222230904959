<template>
  <section class="section py-0">
    <div class="columns">
      <div
        class="column is-narrow-mobile is-1-tablet is-2-desktop is-3-widescreen is-3-fullhd"
      ></div>
      <div
        class="column is-12-mobile is-10-tablet is-8-desktop is-6-widescreen is-6-fullhd"
      >
        <div class="image top-image">
          <img src="@/assets/AnoTest_Title.png" />
          <img src="@/assets/AnoTest_Illust.png" />
        </div>
        <h2 class="title">
          "あああ" じゃもの足りないあなたへ。
        </h2>
        <h3 class="subtitle is-6">
          テスト入力に使える「400文字ぐらいのちょっと長い文章」をお届けします。
        </h3>
        <hr />
        <div class="content">
          <p>
            プログラムのテスト中、備考欄なんかに「それらしいまとまった文章」が欲しくなること、ありませんか？
          </p>
          <p>
            でも時間が無いし、そんなことに労力をかけるのもアレなので、「あああ」とか「テストテストテスト」とか。
          </p>
          <p>
            はたまたちょっとだけ気を利かせて「Lorem ipsum
            …」とか「あのイトウハムのすきとおったしお味…」あたりでしょうか。
          </p>
          <p>
            …でも、本当にそれで良いのでしょうか？取り敢えずのテストだからと安易に考えてしまって良いのでしょうか？
          </p>
          <p class="has-text-weight-bold">
            否！
          </p>
          <p>そう…。気づいてしまったのです。</p>
          <p>
            我々は、「テスト入力」という儀式において、共に働いているメンバーにもう少し、なにかこう、クスッと笑ってもらえるぐらいのおもてなしをして差し上げる努力が必要なのではないか、と。
          </p>
          <p>
            そんな気づきをきっかけに創られたのが、この「{{
              projectName
            }}」です。
          </p>
          <article class="message is-info">
            <div class="message-header">
              <p>使い方</p>
            </div>
            <div class="message-body operation">
              <ol>
                <li>とにかく「START!」ボタンを押すべし！</li>
                <li>
                  ルーレットが指し示したジャンルのテスト用データ（400文字ぐらいのちょっと長めの文章）が表示されるので、コピペして使ってね。
                </li>
                <li>
                  同僚が「キャー！なにこの素敵なテストデータ。天才！」と騒ぎだします。「えー！〇〇さんだったの！？イヤーンすてき～～💛」と素晴らしい展開になることでしょう（知らんけど）。
                </li>
              </ol>
            </div>
          </article>
        </div>
        <roulette />
      </div>
      <div
        class="column is-narrow-mobile is-1-tablet is-2-desktop is-3-widescreen is-3-fullhd"
      ></div>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src
import Roulette from "@/components/Roulette.vue";

export default {
  name: "Home",
  components: {
    Roulette
  },
  data() {
    return {
      projectName: ""
    };
  },
  mounted() {
    this.projectName = process.env.VUE_APP_PROJECT_NAME;
  }
};
</script>

<style lang="scss">
.top-image {
  margin: 0 auto;
  max-width: 480px;
}
.operation {
  ol {
    margin-top: 0;
  }
}
</style>
