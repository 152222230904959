import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/analytics";

const settings = { timestampsInSnapshots: true };

const config = process.env.FIREBASE_CONFIG
  ? process.env.FIREBASE_CONFIG
  : {
      apiKey: process.env.VUE_APP_API_KEY,
      authDomain: process.env.VUE_APP_AUTH_DOMAIN,
      databaseURL: process.env.VUE_APP_DATABASE_URL,
      projectId: process.env.VUE_APP_PROJECT_ID,
      storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
      messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
      appId: process.env.VUE_APP_AP_ID,
      measurementId: process.env.VUE_APP_MEASUREMENT_ID
    };

// console.log(process.env.FIREBASE_CONFIG);
// console.log(config);
// console.log(process.env.NODE_ENV);

firebase.initializeApp(config);
firebase.firestore().settings(settings);

const analytics = firebase.analytics();
analytics.logEvent("notification_received");

export default firebase;
