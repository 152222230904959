<template>
  <footer class="footer mt-6 p-4">
    <div class="level">
      <div class="level-item">
        <div class="has-text-centered">
          <a href="https://twitter.com/goodengineer7" target="_blank">
            <figure class="image is-24x24 inline-icon">
              <img
                class="is-rounded"
                src="https://pbs.twimg.com/profile_images/1284303792523980802/rG0IQk4a_400x400.jpg"
              />
            </figure>
            S(エス) </a
          >Some Rights Reserved.
        </div>
      </div>
      <div class="level-item">
        <router-link to="/about">運営者プロフィール</router-link>
        &nbsp;|&nbsp;
        <a href="https://techfree.jp/contact" target="_blank"
          >取材・お問い合わせ</a
        >
      </div>
    </div>
  </footer>
</template>

<style lang="scss">
.image.inline-icon {
  display: inline-block;
}
</style>
