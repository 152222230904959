<template>
  <div id="external-js"></div>
</template>

<script>
export default {
  mounted() {
    let tag_name = "script";
    let externalScript = document.createElement(tag_name);
    externalScript.setAttribute(
      "src",
      "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
    );
    externalScript.setAttribute("data-ad-client='ca-pub-2787593684673899'");
    document.head.appendChild(externalScript);
  }
};
</script>
