<template>
  <div><ExternalScript /></div>
  <nav id="nav" class="navbar p-2">
    <div class="navbar-brand">
      <a class="navbar-item" href="https://anotest.com/">
        {{ shortProjectName }}
      </a>
      <a
        role="button"
        class="navbar-burger"
        aria-label="menu"
        aria-expanded="false"
        data-target="navbar-anotest"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>
    <div id="navbar-anotest" class="navbar-menu">
      <div class="navbar-end">
        <router-link to="/" class="navbar-item">Home</router-link>
        <router-link to="/about" class="navbar-item"
          >運営者プロフィール</router-link
        >
      </div>
    </div>
  </nav>
</template>

<script>
import ExternalScript from "@/components/LoadScript";
export default {
  name: "Header",
  data() {
    return {
      shortProjectName: String
    };
  },
  mounted() {
    this.shortProjectName = process.env.VUE_APP_SHORT_PROJECT_NAME;
    // burger menu
    document.addEventListener("DOMContentLoaded", () => {
      // Get all "navbar-burger" elements
      const $navbarBurgers = Array.prototype.slice.call(
        document.querySelectorAll(".navbar-burger"),
        0
      );

      // Check if there are any navbar burgers
      if ($navbarBurgers.length > 0) {
        // Add a click event on each of them
        $navbarBurgers.forEach(el => {
          el.addEventListener("click", () => {
            // Get the target from the "data-target" attribute
            const target = el.dataset.target;
            const $target = document.getElementById(target);

            // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
            el.classList.toggle("is-active");
            $target.classList.toggle("is-active");
          });
        });
      }
    });
  }
};
</script>
