<template>
  <div class="p-3">
    <div class="columns">
      <div class="column is-narrow px-0">
        <p
          xmlns:dct="http://purl.org/dc/terms/"
          xmlns:vcard="http://www.w3.org/2001/vcard-rdf/3.0#"
        >
          <a
            rel="license"
            href="https://creativecommons.org/publicdomain/zero/1.0/"
            title="To the extent possible under law, S(エス) has waived all copyright and related or neighboring rights to result text data for testing on anotest.com. This work is published from: Japan."
          >
            <img
              src="https://i.creativecommons.org/p/zero/1.0/88x31.png"
              style="border-style: none;"
              alt="CC0"
            />
          </a>
        </p>
      </div>
      <div class="column">
        <div class="content is-size-7 has-text-left">
          テキストエリア内に表示されるテストデータ用テキストは CC0
          ライセンスを適用しています。個人、商用問わずご自由に無料でお使いください。ただし、このデータを用いた結果発生するいかなる事象（恋愛への発展、結婚、破局、解雇、懲罰、泥仕合など）について一切の責任を負いません。ご利用は建設的に！
        </div>
      </div>
    </div>
  </div>
</template>
