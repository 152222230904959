<template>
  <Winwheel
    ref="wheel"
    :segments="options"
    :win-wheel-options="winWheelOptions"
    :prize-name="prizeName"
    :prize-desc="prizeDesc"
    :prize-url="prizeUrl"
    prize-wrapper-class="notification prize-wrapper"
    v-model:modalPrize="isShowPrize"
    transision-name="slidein"
  >
    <template v-slot:wheel-upper>
      <section class="section">
        <p class="title is-5 mb-2">
          さて、今回あなたが入力するテストデータは……
        </p>
        <p class="is-size-3">👇</p>
        <button class="button is-primary m-4 is-size-3" @click="startWheel()">
          START!
        </button>
      </section>
    </template>
    <template v-slot:wheel-lower>
      <section class="section">
        <p class="is-size-3">👇</p>
      </section>
    </template>
    <template v-slot:prize v-if="isShowPrize">
      <div class="card">
        <div class="card-content p-2">
          <p class="title is-5 mb-2">こちらっっ！</p>
          <textarea
            id="prizeDescTextarea"
            class="textarea"
            v-model="prizeDesc"
            :rows="prizeDescRows"
            @change="setCopyButtonString(false)"
          ></textarea>
          <p v-if="prizeUrl" class="notification mt-3">
            <span class="tag is-link">参照URL⇒</span>&nbsp;<a
              :href="prizeUrl"
              target="_blank"
              >{{ prizeUrl }}</a
            >
          </p>
          <button class="button is-info mt-3" @click="copyToClipboard()">
            {{ copyButtonString }}</button
          ><br />
          <a
            href="https://twitter.com/intent/tweet?text=%E3%83%86%E3%82%B9%E3%83%88%E3%81%AF%E5%A4%A7%E4%BA%8B%E3%80%82%E3%83%86%E3%82%B9%E3%83%88%E3%83%87%E3%83%BC%E3%82%BF%E3%82%82%E5%A4%A7%E4%BA%8B%E2%80%A6%E3%81%A7%E3%81%99%E3%82%88%E3%81%A3%F0%9F%92%9B%0A%23%E3%81%82%E3%81%AE%E3%83%86%E3%82%B9%0Ahttps%3A%2F%2Fanotest.com%2F"
            target="_blank"
          >
            <button class="button is-info mt-3">
              Tweet
            </button>
          </a>
          <div>
            <license />
          </div>
        </div>
      </div>
    </template>
  </Winwheel>

  <!--
    <canvas id="canvas" width="880" height="300"> Canvas not supported...</canvas>
  -->
</template>

<script>
import firebase from "@/firebase/firebase";
import VueWinwheel from "vue-winwheel/vue-winwheel";
import License from "./License.vue";
// import Winwheel from "@/assets/js/Winwheel.js";

export default {
  name: "Roulette",
  components: {
    Winwheel: VueWinwheel,
    License: License
  },
  props: {
    msg: String
  },
  data() {
    return {
      wheel: Object,
      options: [],
      winWheelOptions: {
        textFontFamily: "YuGothic",
        textFontSize: 24,
        textMargin: 4,
        textFontWeight: "Normal",
        // textOrientation: "curved",
        textAligment: "inner",
        // textOrientation: "vertical",
        outterRadius: 460,
        innerRadius: 23,
        lineWidth: 6,
        animation: {
          type: "spinToStop",
          duration: 5,
          spins: 5,
          callbackFinished: this.showTestContents
        }
      },
      db: null,
      prizeName: "test prize",
      prizeDesc: "test prize desc",
      prizeDescRows: 4,
      prizeUrl: "",
      isShowPrize: false,
      copyButtonString: ""
    };
  },
  created() {
    this.db = firebase.firestore();
    this.setCategories();
    this.setCopyButtonString(false);
  },
  mounted() {},
  methods: {
    startWheel() {
      this.$refs.wheel.hidePrize();
      this.$refs.wheel.startSpin();
      this.setCopyButtonString(false);
      firebase.analytics().logEvent("clickStart");
    },
    setCategories() {
      this.options.splice(0);
      this.options = this.db
        .collection("categories")
        .get()
        .then(query => {
          let config = [];
          query.forEach(doc => {
            config.push({
              textFillStyle: doc.data().textFillStyle,
              fillStyle: doc.data().fillStyle,
              text: doc.data().text
            });
          });
          this.options = config;
        });
    },
    async showTestContents(indicatedSegment) {
      this.prizeName = indicatedSegment.text;
      const prizeContent = await this.getContent(this.prizeName);
      this.prizeDesc = prizeContent.body.replace(/\\n/g, "\n");
      this.prizeUrl = prizeContent.url;
      const lines = this.prizeDesc.match(/.*\n/g) || [this.prizeDesc]; // 改行が見つからない場合body1行のみをarrayにセットする。
      const linecounts = lines.map(desc => desc.length);
      this.prizeDescRows =
        lines.length +
        linecounts.reduce((sum, linelength) => {
          return sum + Math.floor(linelength / 38);
        }, 0);
      this.$refs.wheel.showPrize();
    },
    async getContent(category) {
      let contents = [];
      await this.db
        .collection("contents")
        .where("category", "==", category)
        .get()
        .then(function(querySnapshot) {
          contents = querySnapshot.docs;
          // querySnapshot.forEach(doc => {
          //   contents = doc;
          // });
        })
        .catch(function(error) {
          console.log("ERROR: ", error);
        });
      const randomIndex = Math.floor(Math.random() * contents.length);
      return contents[randomIndex].data();
    },
    copyToClipboard() {
      const textareaPrizeDesc = document.getElementById("prizeDescTextarea");
      textareaPrizeDesc.select();
      document.execCommand("copy");
      this.setCopyButtonString(true);
    },
    setCopyButtonString(isCopied) {
      if (isCopied) {
        this.copyButtonString = "Copyしましたっ！";
      } else {
        this.copyButtonString = "Copyする！";
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
h3 {
  margin: 40px 0 0;
}
/*
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
*/
a {
  color: #42b983;
}

.prize-wrapper {
  padding: 6px;
  min-height: 7em;
  border: 8px dashed #6f6f6f;
}

// prize area action.
.fade-enter-from {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 10s;
}

.fade-enter-to {
  opacity: 1;
}

.fade-leave-from {
  opacity: 1;
}

.fade-leave-to {
  opacity: 0;
}

.slidein-enter-from {
  opacity: 0;
  transform: translateY(-20px);
}
.slidein-enter-active {
  transition: opacity 300ms ease-out, transform 300ms ease-out;
}

.slidein-leave-to {
  opacity: 0;
  transform: scale(0.97);
}

.slidein-leave-active {
  transition: opacity 270ms ease-out, transform 270ms ease-out;
}
</style>
